import { createAction } from 'redux-act';
import { IImage } from '../../modules/reducers';
import { ISlide } from './../types/slideshow';

export const clearSelection = createAction('CLEAR_SELECTION');
export const updateSelection =
  createAction<{ newSelectedCards?: ISlide[] | IImage[]; newLastSelectedIndex?: number }>(
    'UPDATE_SELECTION'
  );
export const setInsertIndex = createAction<{ [key: string]: number }>('SET_INSERT_INDEX');
export const clearAllDraggableState = createAction('CLEAR_ALL_DRAGGABLE_STATE');
