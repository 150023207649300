import { createReducer } from 'redux-act';
import { IShopReducerState } from '../../types/shop';
import {
  fetchCouponsAction,
  fetchCouponsSuccessAction,
  fetchOrderImagesSuccessAction,
  fetchLaboratoriesSuccessAction,
  fetchOrderListForUserSuccessAction,
  fetchProductGroupsSuccessAction,
  fetchProductPricingListSuccessAction,
  fetchUserAccountBalanceSuccessAction,
  fetchUserPricingSuccessAction,
  getGlobalShopSettingsSuccess,
  setSelectedPriceListAction,
  searchCollectionsSuccessAction,
  setSalesAutomationAction,
  setPayDocumentsSuccessAction,
  fetchProductsSuccessAction,
  userHasEnoughInvoicingDataSuccessAction,
  fetchProductUserPricingGroupsListSuccessAction
} from '../../../modules/actions';
import {
  clearDigitalPricingDetails,
  fetchDigitalPricingListsSuccessAction,
  setCurrentDigitalPricingListSuccessAction,
  setIsCreatePackageModalOpen
} from '../../../modules/actions/digital_pricing';

const initialState: IShopReducerState = {
  digitalPricing: {
    lists: [],
    isCreatePackageModalOpen: false,
    currentlyOpenedDigitalList: {
      id: '',
      name: '',
      userId: '',
      packages: []
    }
  },
  payDocuments: [],
  pricingList: [],
  pricingGroups: [],
  selectedPriceList: '',
  productUserPricing: [],
  userBalance: {
    balance: {
      amount: 0,
      pendingPayout: false,
      minBalanceAmountForPayout: 2500
    },
    pendingPayout: {
      amount: 0,
      pendingPayout: false,
      minBalanceAmountForPayout: 2500
    }
  },
  laboratories: [],
  globalSettings: null,
  coupons: [],
  orders: {
    list: [],
    meta: {
      totalAmount: 0
    }
  },
  orderImages: [],
  productGroups: [],
  products: [],
  isCouponsFetched: false,
  collectionsSearchResult: [],
  salesAutomation: {
    bulkUpdateInProgress: false
  },
  userHasEnoughInvoicingData: null
};

export const shop = createReducer<IShopReducerState>({}, initialState);
shop.on(fetchProductPricingListSuccessAction, (state, payload) => ({
  ...state,
  pricingList: payload
}));
shop.on(fetchProductUserPricingGroupsListSuccessAction, (state, payload) => ({
  ...state,
  pricingGroups: payload
}));
shop.on(setSelectedPriceListAction, (state, payload) => ({
  ...state,
  selectedPriceList: payload
}));
shop.on(fetchUserPricingSuccessAction, (state, payload) => ({
  ...state,
  productUserPricing: payload
}));
shop.on(fetchUserAccountBalanceSuccessAction, (state, payload) => ({
  ...state,
  userBalance: payload
}));
shop.on(fetchCouponsAction, (state) => ({
  ...state,
  isCouponsFetched: true
}));
shop.on(fetchCouponsSuccessAction, (state, payload) => ({
  ...state,
  coupons: payload
}));
shop.on(fetchProductGroupsSuccessAction, (state, payload) => ({
  ...state,
  productGroups: payload
}));
shop.on(fetchProductsSuccessAction, (state, payload) => ({
  ...state,
  products: payload
}));
shop.on(getGlobalShopSettingsSuccess, (state, payload) => ({
  ...state,
  globalSettings: payload
}));
shop.on(fetchOrderListForUserSuccessAction, (state, payload) => ({
  ...state,
  orders: payload
}));
shop.on(fetchOrderImagesSuccessAction, (state, payload) => ({
  ...state,
  orderImages: payload
}));
shop.on(fetchLaboratoriesSuccessAction, (state, payload) => ({
  ...state,
  laboratories: payload
}));

shop.on(fetchDigitalPricingListsSuccessAction, (state, payload) => ({
  ...state,
  digitalPricing: {
    ...state.digitalPricing,
    lists: payload.digitalPricingLists
  }
}));

shop.on(setCurrentDigitalPricingListSuccessAction, (state, payload) => ({
  ...state,
  digitalPricing: {
    ...state.digitalPricing,
    currentlyOpenedDigitalList: payload
  }
}));

shop.on(setIsCreatePackageModalOpen, (state, payload) => ({
  ...state,
  digitalPricing: {
    ...state.digitalPricing,
    isCreatePackageModalOpen: payload
  }
}));

shop.on(clearDigitalPricingDetails, (state) => ({
  ...state,
  digitalPricing: {
    ...state.digitalPricing,
    currentlyOpenedDigitalList: initialState.digitalPricing.currentlyOpenedDigitalList
  }
}));

shop.on(searchCollectionsSuccessAction, (state, payload) => ({
  ...state,
  collectionsSearchResult: payload
}));

shop.on(setSalesAutomationAction, (state, payload) => ({
  ...state,
  salesAutomation: payload
}));

shop.on(setPayDocumentsSuccessAction, (state, payload) => ({
  ...state,
  payDocuments: payload
}));
shop.on(userHasEnoughInvoicingDataSuccessAction, (state, payload) => ({
  ...state,
  userHasEnoughInvoicingData: payload
}));
