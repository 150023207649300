import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Input, Progress } from 'reactstrap';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';
import querystring from 'query-string';
import { debounce as _debounce } from 'lodash';

class EditWatermark extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.watermarkImageSelected = this.watermarkImageSelected.bind(this);
    // this._throttle = null;

    this.setPreviewUrl = _debounce(this.setPreviewUrl.bind(this), 1500, {
      leading: true
    });
  }

  componentDidMount() {
    this.setPreviewUrl();
  }

  componentDidUpdate() {
    this.setPreviewUrl();
  }

  setPreviewUrl() {
    const { watermark } = this.props;
    if (
      watermark &&
      ((watermark.type === 'image' && watermark.watermarkPublicKey) || watermark.type === 'text')
    ) {
      const query = querystring.stringify(watermark);
      this.setState({
        previewUrl: `${window.API_URL || ''}/api/image/previewWatermark?${query}`
      });
    }
  }

  async watermarkImageSelected(file) {
    this.setState({ uploadingWatermarkImage: true });
    const { uploadWatermarkImage, watermark, notify } = this.props;

    await uploadWatermarkImage(watermark._id, file, notify);

    this.setState({ uploadingWatermarkImage: false });
  }

  render() {
    const { watermark } = this.props;
    const { uploadingWatermarkImage } = this.state;
    if (!watermark) return null;

    return (
      <Col
        md={watermark.watermarkPublicKey || watermark.type === 'text' ? 6 : 12}
        sm="12"
        className="text-center"
      >
        {((watermark.type === 'image' && watermark.watermarkPublicKey) ||
          watermark.type === 'text') && (
          <img
            src={this.state.previewUrl}
            className="img-fluid max-h-300 pb-3 m-4"
            alt={Texts[window.LANGUAGE_SW].previewLoading}
          />
        )}
        {uploadingWatermarkImage && <Progress animated color="success" value="100" />}
        {!uploadingWatermarkImage && watermark.type === 'image' && !watermark.watermarkPublicKey && (
          <div>
            <p className="text-justify text-muted">
              {Texts[window.LANGUAGE_SW].watermark.uploadImageDesc}
            </p>
            <label className="btn btn-primary cursor-pointer w-100-p">
              <Input
                type="file"
                onChange={({ target: { files } }) => this.watermarkImageSelected(files[0])}
                accept="image/*"
                multiple={false}
                className="hidden-upload"
                disabled={uploadingWatermarkImage}
              />
              <i className="icon-picture" /> {Texts[window.LANGUAGE_SW].watermark.uploadImage}
            </label>
          </div>
        )}
      </Col>
    );
  }
}

function mapStateToProps({ notify }) {
  return { notify };
}

export default connect(mapStateToProps, actions)(EditWatermark);
