import { createSelector } from 'reselect';
import { IStoreState } from '../types';
import { ifImplementsKeys } from '../../helpers/objectFunctions';
import { BILLING_ADD_FIELDS } from '../../modules/constants/subscriptions';
import { IAdressInterface, IPaymentProblem } from '../../modules/types/paymentInfo';

export const getPaymentInfo = (state: IStoreState) => state.paymentInfo;
export const getPaymentsWithProblem = (state: IStoreState) => {
  const isStripe = state.paymentInfo.paymentProvider === 'stripe';
  const subscriptionsWithPaymentProblem = getPaymentInfo(state).subscriptions.filter(
    (item) => item.isPaymentProblem && (isStripe ? item.receiptUrl : item.renewUrl)
  );
  const oneTimePurchasesWithPaymentProblem = getPaymentInfo(state).oneTimePurchases.filter(
    (item) => item.isPaymentProblem && item.renewUrl
  );
  const latestPaymentProblems = [
    ...subscriptionsWithPaymentProblem,
    ...oneTimePurchasesWithPaymentProblem
  ].map((item) => ({
    ...item,
    disabled: false
  }));

  // disable feature temporarily
  if (false && isStripe) {
    const paymentProblems: Array<IPaymentProblem & { date?: string; disabled?: boolean }> = [
      ...(state.paymentInfo.paymentProblems || []),
      ...latestPaymentProblems.map((item: any) => ({
        receiptUrl: item.receiptUrl || item.renewUrl,
        productName: item.productName,
        invoiceId: item.invoiceId || item.lastInvoiceId
      }))
    ];

    // add date of the related invoice to payment problems
    paymentProblems.forEach((paymentProblem) => {
      const invoice = state.paymentInfo.invoices.find(
        (item) => item.invoiceId === paymentProblem.invoiceId
      );
      if (invoice) {
        paymentProblem.date = invoice.date;
      }
    });

    // sort payment problems by invoice's date
    paymentProblems.sort((a, b) => {
      if (!a.date || !b.date) {
        return 0;
      }
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    paymentProblems.forEach((paymentProblems, _, self) => {
      paymentProblems.disabled = paymentProblems.invoiceId !== self[0].invoiceId;
    });

    return paymentProblems;
  } else {
    return latestPaymentProblems;
  }
};

export const getIsBillingAddressFilled = createSelector(getPaymentInfo, ({ address, userId }) => {
  let isFilled = true;

  if (userId) {
    if (ifImplementsKeys(address, BILLING_ADD_FIELDS)) {
      Object.keys(address).forEach((key) => {
        if (
          !address[key as keyof IAdressInterface] ||
          address[key as keyof IAdressInterface] === ''
        ) {
          isFilled = false;
        }
      });
    } else {
      return false;
    }
  }

  return isFilled;
});

export const getIsSepaPending = (state: IStoreState) =>
  getPaymentInfo(state).subscriptions.some((item) => item.isSepaPending) ||
  getPaymentInfo(state).oneTimePurchases.some((item) => item.isSepaPending);
