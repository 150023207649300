import { SagaIterator } from 'redux-saga';
import { put, call, select, delay } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  changeModalState,
  errorsGlobalError,
  updateWatermarkProcessed
} from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import { ISagaAction } from '../../../modules/types';
import { IStartWatermarksProcessingAction } from '../../../modules/types/watermarks';
import { getCollectionId, getImagesByGalleryID } from '../../../modules/selectors';

export const startWaterMarkProcessingSaga = function* ({
  payload
}: ISagaAction<IStartWatermarksProcessingAction>): SagaIterator {
  const collection_id = yield select(getCollectionId);
  const { gallery_id } = payload;

  if (gallery_id) {
    const galleryImages = yield select((state) => getImagesByGalleryID(state)(gallery_id));
    if (!galleryImages.length) return;
  }

  let stopProcess = false;

  yield put(changeModalState({ key: 'watermarkProcessingModal', state: true }));

  while (!stopProcess) {
    try {
      const response = yield call(Api.Watermarks.processing, { ...payload, collection_id });
      ApiErrors.checkOnApiError(response);
      const { processedWatermarksCount, totalImagesCount, failedWatermarksCount } = response;
      yield put(updateWatermarkProcessed(response));
      const totalProcessedwithFailed = failedWatermarksCount + processedWatermarksCount;

      if (totalProcessedwithFailed === totalImagesCount) {
        stopProcess = true;
        if (processedWatermarksCount === totalImagesCount) {
          yield delay(5000);
          yield put(changeModalState({ key: 'watermarkProcessingModal', state: false }));
        }
      }
    } catch (e) {
      stopProcess = true;
      yield put(changeModalState({ key: 'watermarkProcessingModal', state: false }));
      yield put(errorsGlobalError(e as ErrorEvent));
    }

    yield delay(5000);
  }
};
