import { createSelector } from 'reselect';
import { ISlideshow } from './../types/slideshow';

export const getSlideshowsList = (state: { slideshowsList: ISlideshow[] }) => {
  return state.slideshowsList;
};
export const getSlideshowsById = createSelector(
  getSlideshowsList,
  (slideshowsList: ISlideshow[]) => (albumId: string) => {
    return slideshowsList.filter((slideshow) => slideshow.albumId === albumId);
  }
);
