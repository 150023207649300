import { createReducer } from 'redux-act';
import { ICountriesReducerState, ICountry } from '../../../modules/types';
import { fetchCountriesSuccessAction } from '../../../modules/actions/countries';

const initialState: ICountriesReducerState = {
  items: [],
  isFetched: false
};

export const countries = createReducer<ICountriesReducerState>({}, initialState);
countries.on(fetchCountriesSuccessAction, (state: ICountriesReducerState, payload: ICountry[]) => ({
  items: payload,
  isFetched: true
}));
