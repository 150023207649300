import styled, { css } from 'styled-components';
import theme from '../../assets/css/theme';

interface ILabelItem {
  isSmall?: boolean;
  isBold?: boolean;
  cursor?: string;
  margin?: string;
}

const smallStyles = css`
  font-weight: 400;
`;
const boldStyles = css`
  font-weight: bolder;
`;

export const LabelItem = styled.label<ILabelItem>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin || '0 0 5px 0'};
  cursor: ${(props) => props.cursor || 'initial'};
  ${(props) => {
    const { isSmall, isBold } = props;
    const small = isSmall && smallStyles;
    const bold = isBold && boldStyles;
    return [small, bold];
  }};

  i {
    color: ${theme.commonColors.second};
    margin-left: 5px;
  }
`;
export default LabelItem;
