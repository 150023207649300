import { createReducer } from 'redux-act';
import * as actions from '../../../modules/actions';
import { ISlideshow } from '../../../modules/types';

const initialState: ISlideshow[] = [];

export const slideshowsList = createReducer<ISlideshow[]>({}, initialState);
slideshowsList.on(actions.fetchSlideshowsListSuccess, (state, payload) => {
  const updatedSlideshowsList: ISlideshow[] = [...state];
  const slideshowsListIds = updatedSlideshowsList.map((slideshow) => slideshow.id);

  payload.forEach((res: any) => {
    if (!res.result) return;
    if (updatedSlideshowsList.length) {
      if (!slideshowsListIds.includes(res.result.id)) {
        updatedSlideshowsList.push(res.result);
      }
    } else {
      updatedSlideshowsList.push(res.result);
    }
  });

  return updatedSlideshowsList;
});
slideshowsList.on(actions.removeSlideshow, (state, { slideshowId }) => {
  return state.filter((slideshow: ISlideshow) => slideshow.id !== slideshowId);
});
slideshowsList.on(
  actions.changeSlideshowPublishStatusSuccess,
  (_, { slideshowsList }) => slideshowsList
);
slideshowsList.on(actions.clearFetchedSlideshowsList, () => initialState);
