import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Card, CardBody, Col, Row, Form, Button } from 'reactstrap';
import Texts from '../../json/texts.json';
import InputGroup from '../Forms/InputGroup';
import Select from '../Forms/Select';
import { Field, reduxForm } from 'redux-form';
import approve from 'approvejs';
import LanguageSelectSoftware from '../User/Account/LanguageSelectSoftware';
import LanguageSelectApps from '../User/Account/LanguageSelectApps';

let fields = {};

function validate(values) {
  const errors = {};
  if (fields.passwordConfirmation) {
    fields.passwordConfirmation.rules.equal.value = values.password;
  }

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // setup here to get the langauge right
    fields = {
      password: {
        type: 'password',
        title: Texts[window.LANGUAGE_SW].password,
        placeholder: Texts[window.LANGUAGE_SW].password,
        rules: {
          required: { message: Texts[window.LANGUAGE_SW].requiredInput },
          min: {
            min: 6,
            message: Texts[window.LANGUAGE_SW].min6CharsPassword
          }
        }
      },
      knownFrom: {
        type: 'select',
        value: 'pleaseSelect',
        title: Texts[window.LANGUAGE_SW].knownFrom,
        options: Object.keys(Texts[window.LANGUAGE_SW].knownFromOptions).map((key) => ({
          value: key,
          displayValue: Texts[window.LANGUAGE_SW].knownFromOptions[key]
        })),
        rules: {}
      }
    };
  }

  async submit(values) {
    const errors = validate(values);
    if (Object.keys(errors).length === 0) {
      const { setCurrentUserValue, changeCurrentUserPassword } = this.props;

      if (values.knownFrom) {
        await setCurrentUserValue({
          key: 'knownFrom',
          value: values.knownFrom,
          notSetInUI: true
        });
      }

      changeCurrentUserPassword(values.password, null, false);
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit(this.submit)}>
            <h1>{Texts[window.LANGUAGE_SW].setPassword}</h1>
            <p className="text-muted">{Texts[window.LANGUAGE_SW].onboardingPasswordMessage}</p>
            {Object.keys(fields).map((fieldKey, index) =>
              fields[fieldKey].type === 'select' ? (
                <Field component={Select} key={index} name={fieldKey} field={fields[fieldKey]} />
              ) : (
                <Field
                  component={InputGroup}
                  key={index}
                  name={fieldKey}
                  field={fields[fieldKey]}
                />
              )
            )}
            <Row>
              <Col xs="12">
                <Button
                  color="primary"
                  className="px-4 w-100-p"
                  disabled={this.props.pristine || this.props.invalid || this.props.submitting}
                  type="submit"
                >
                  {Texts[window.LANGUAGE_SW].setPassword}
                </Button>
              </Col>
            </Row>
            {this.props.auth.whiteLabel && (
              <Row className="m-t-10">
                <Col xs="12">
                  <hr />
                  <LanguageSelectSoftware />
                  <div className="m-t-10" />
                  <LanguageSelectApps
                    selectedValue={this.props.auth.languageApp}
                    onChange={({ target: { value } }) =>
                      this.props.updateDefaultUserLanguages({
                        languageApp: value
                      })
                    }
                  />
                </Col>
              </Row>
            )}
          </Form>
        </CardBody>
      </Card>
    );
  }
}

SetPassword = reduxForm({
  form: 'changePasswordForm',
  validate
})(SetPassword);

SetPassword = connect(
  (state) => ({
    notify: state.notify,
    auth: state.auth
  }),
  actions
)(SetPassword);

export default SetPassword;
