import Texts from '../json/texts.json';
import history from '../helpers/history';
import queryString from 'query-string';

function generalError(notify) {
  notify &&
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 5000
    });
}

export function handleErrorWithRedirectIfNotAuth(e, notify = null) {
  const { loginAttempt } = queryString.parse(history.location.search);
  const notAllowedToRedirect = ['#/account', '#/subscription'];
  const notAllowedRoutes = notAllowedToRedirect.every(
    (item) => !window.location.hash.startsWith(item)
  );

  if (e.response) {
    switch (e.response.status) {
      case 401:
        notify &&
          notify({
            title: Texts[window.LANGUAGE_SW].error,
            message: Texts[window.LANGUAGE_SW].sessionNotFoundError,
            status: 'error',
            position: 'tc',
            dismissible: true,
            dismissAfter: 5000
          });
        if (history.location.pathname !== '/logout' && loginAttempt !== '1') {
          setTimeout(() => history.push('/logout'), 1000);
        }
        break;
      case 402:
        notify &&
          notify({
            title: Texts[window.LANGUAGE_SW].error,
            message: Texts[window.LANGUAGE_SW].subscription.paymentRequired,
            status: 'error',
            position: 'tc',
            dismissible: true,
            dismissAfter: 30000
          });
        if (window.location.hash && notAllowedRoutes) {
          history.push('/account');
        }
        break;
      case 423:
        notify &&
          notify({
            title: Texts[window.LANGUAGE_SW].error,
            message: Texts[window.LANGUAGE_SW].subscription.chargebackFeeRequired,
            status: 'error',
            position: 'tc',
            dismissible: true,
            dismissAfter: 30000
          });
        if (window.location.hash && notAllowedRoutes) {
          history.push('/account');
        }
        break;
      default:
        generalError(notify);
    }
  } else {
    generalError(notify);
  }
}
