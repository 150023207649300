import React, { useEffect, useState } from 'react';
import { EmailTemplateSelect } from './EmailTemplateSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCollectionsNew,
  getDeliveryFormSelector,
  getSelectedEmailTemplateSelector
} from '../../modules/selectors';
import { CheckBox, InputItem } from '../Forms';
import { getTranslationKey } from '../../modules/utils';
import { partial, mapKeys, get, keyBy } from 'lodash';
import { EmailRecipientField } from './EmailRecipientField';
import { sendEmailAction } from '../../modules/actions';
import styled from 'styled-components';
import { validateFieldStore } from '../../modules/utils/helpers/validations';
import { IDictionary } from '../../modules/types';
import useDeliveryForm from './useDeliveryForm';

const SendButton = styled.button`
  margin: 10px 0 20px;
`;

type TStateField =
  | 'subject'
  | 'recipientsEmails'
  | 'emailsCollectedWithPurchase'
  | 'sendCopyToUser'
  | 'showPasswordInMail';

interface IProps {}

// tslint:disable-next-line:max-func-body-length
export const SendEmailForm: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { password } = useSelector(getCollectionsNew);
  const { subject, html, id } = useSelector(getSelectedEmailTemplateSelector);
  const { html: editedHtml, selectedTemplateId } = useSelector(getDeliveryFormSelector);
  const [sendData, setSendData] = useDeliveryForm();
  const [errors, updateErrors] = useState<IDictionary<any>>({
    subject: {
      titleKey: 'collectionSettings.sendEmail.subjectLabel',
      validations: 'required',
      error: ''
    },
    recipientsEmails: {
      titleKey: 'collectionSettings.sendEmail.email',
      validations: 'required, emailsList',
      error: ''
    }
  });

  useEffect(() => {
    setSendData({ ...sendData, subject });
  }, [subject]);

  const changeFormHandler = (key: TStateField, e: unknown | React.SyntheticEvent) => {
    const checkboxItems = ['emailsCollectedWithPurchase', 'sendCopyToUser', 'showPasswordInMail'];
    const newCheckboxValue = !sendData[key];

    const value = checkboxItems.includes(key) ? newCheckboxValue : e;

    updateErrors({ ...errors, [key]: { ...errors[key], error: '' } });
    setSendData({ ...sendData, [key]: value });
  };

  const sendEmailHandler = () => {
    const validationErrors: any = [];
    mapKeys(errors, (data, key) => {
      const value = get(sendData, key, false);
      const error = validateFieldStore(key, { ...data, value }, {});

      if (!error) return;

      const stateError = errors[error.name];
      validationErrors.push({
        ...stateError,
        error: error.errorText,
        name: error.name
      });
    });
    const stateErrors = keyBy(validationErrors, 'name');
    updateErrors({ ...errors, ...stateErrors });

    if (validationErrors.length) return;

    const oldHtml = selectedTemplateId === id && editedHtml ? editedHtml : html;

    const mailText = oldHtml.replace(/<br\s*[\/]?>/gi, '\n');

    const isPasswordPresent = password && sendData.showPasswordInMail;

    dispatch(
      sendEmailAction({
        emailTemplateId: id,
        recipientsEmails: sendData.recipientsEmails,
        subject: sendData.subject,
        emailsCollectedWithPurchase: sendData.emailsCollectedWithPurchase,
        sendCopyToUser: sendData.sendCopyToUser,
        html: mailText,
        showPasswordInMail: !!isPasswordPresent
      })
    );
  };

  return (
    <>
      <EmailTemplateSelect />
      <InputItem
        label={getTranslationKey('collectionSettings.sendEmail.subjectLabel')}
        placeholder={getTranslationKey('collectionSettings.sendEmail.subjectPlaceholder')}
        value={sendData.subject}
        changeCallback={partial(changeFormHandler, 'subject')}
        errorText={errors.subject.error}
      />
      <EmailRecipientField errorText={errors.recipientsEmails.error} />
      <CheckBox
        checked={sendData.emailsCollectedWithPurchase}
        text={getTranslationKey('collectionSettings.sendEmail.withAPurchaseLabel')}
        onChange={partial(changeFormHandler, 'emailsCollectedWithPurchase')}
        tooltipID="purchase-checkbox"
        tooltipText={getTranslationKey('collectionSettings.sendEmail.withAPurchaseTooltip')}
      />
      <CheckBox
        checked={sendData.sendCopyToUser}
        text={getTranslationKey('collectionSettings.sendEmail.sendCopyToUser')}
        onChange={partial(changeFormHandler, 'sendCopyToUser')}
        tooltipID="send-me-copy"
      />
      <SendButton className="btn btn-success w-100" onClick={sendEmailHandler}>
        {getTranslationKey('collectionSettings.sendEmail.sendEmailButton')}
      </SendButton>

      {password && (
        <CheckBox
          checked={sendData.showPasswordInMail}
          text={getTranslationKey('collectionSettings.sendEmail.togglePasswordInEmails')}
          onChange={partial(changeFormHandler, 'showPasswordInMail')}
        />
      )}
    </>
  );
};
