import { createReducer } from 'redux-act';
import { updateWatermarkProcessed } from '../../../modules/actions';

export type WatermarkPositions = 'Center' | 'NorthEast' | 'NorthWest' | 'SouthWest' | 'SouthEast';

export interface IWatermark {
  description: string;
  id: string;
  jimpFont: string;
  opacity: number;
  positions: WatermarkPositions[];
  scale: number;
  text: string;
  type: string;
  __v: number;
  _id: string;
  _user: string;
}

export interface IWatermarksProcessState {
  failedImagesNames: string[];
  failedWatermarksCount: number;
  processedWatermarksCount: number;
  totalImagesCount: number;
}

const initialState = {
  failedImagesNames: [''],
  failedWatermarksCount: 1,
  processedWatermarksCount: 0,
  totalImagesCount: 12
};

export const watermarksProcess = createReducer<IWatermarksProcessState>({}, initialState);
watermarksProcess.on(
  updateWatermarkProcessed,
  (state: IWatermarksProcessState, payload: IWatermarksProcessState) => ({
    ...state,
    ...payload
  })
);
