import React from 'react';
import { FaSortAlphaDown, FaSortAlphaUpAlt } from 'react-icons/fa';
import { partial } from 'lodash';
import { NavLink } from 'reactstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IPreset } from '../../../modules/reducers/presets';
import { getTranslationKey } from '../../../modules/utils/helpers';
import theme from '../../../assets/css/theme';
import { IGalleryTag } from '../../../modules/types';
import { SelectItem, GalleryTagsInput, InputItem } from '../../../components';
import { usePresetsSettingsSelectsData } from '../../../modules/hooks';
import { getAccessRules } from '../../../modules/selectors/accessRules';
import { getMessages } from '../../../modules/selectors/message';

const UpgradeText = styled(NavLink)`
  color: ${theme.textColors.link};
`;

const OrderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OrderButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
  max-height: 35px;
  margin-top: auto;
  margin-bottom: 8px;
  svg {
    color: ${theme.commonColors.second};
  }
`;

interface IProps {
  preset: IPreset;
  setFormValueCallback: (data: Partial<IPreset>) => void;
}

export const PresetSelects = ({ preset, setFormValueCallback }: IProps) => {
  const {
    _domain,
    _userbrand,
    _watermark,
    language,
    sortBy,
    sortOrder = 'asc',
    _id: galleryPresetId,
    messages: presetMessages = [],
    expiresInDays
  } = preset;
  const messages = useSelector(getMessages);
  const { planConfig } = useSelector(getAccessRules);
  const { brandsOptions, domainsOptions, watermarksOptions, languageOptions, sortingOptions } =
    usePresetsSettingsSelectsData();
  const changeFormHandler = (path: string, value: string | number | boolean | string[]) => {
    setFormValueCallback({ [path]: value });
  };

  const presetsTagsListener = (galleryTags: IGalleryTag[]) => {
    changeFormHandler(
      'tags',
      galleryTags.map((tag) => tag.value)
    );
  };

  return (
    <>
      <SelectItem
        label={getTranslationKey('galleryPresets.settingsTab.brandLabel')}
        defaultOptionsText={getTranslationKey('galleryPresets.settingsTab.brandLabel')}
        value={_userbrand}
        changeCallback={partial(changeFormHandler, '_userbrand')}
        options={brandsOptions}
      />
      <SelectItem
        label={getTranslationKey('galleryPresets.settingsTab.domainLabel')}
        defaultOptionsText={getTranslationKey('galleryPresets.settingsTab.domainLabel')}
        value={_domain}
        changeCallback={partial(changeFormHandler, '_domain')}
        options={domainsOptions}
        disabled={planConfig.domains === 0}
      />
      {planConfig.domains === 0 ? (
        <UpgradeText to="/subscription/pricing">
          <small>{getTranslationKey('customDomains.upgradeAccountForDomain')}</small>
        </UpgradeText>
      ) : null}
      <SelectItem
        label={getTranslationKey('galleryPresets.settingsTab.waterMarks')}
        defaultOptionsText={getTranslationKey('galleryPresets.settingsTab.waterMarks')}
        value={_watermark}
        changeCallback={partial(changeFormHandler, '_watermark')}
        options={watermarksOptions}
      />
      <SelectItem
        label={getTranslationKey('galleryPresets.settingsTab.langLabel')}
        defaultOptionsText={getTranslationKey('galleryPresets.settingsTab.langLabel')}
        value={language}
        changeCallback={partial(changeFormHandler, 'language')}
        options={languageOptions}
      />
      <SelectItem
        label={getTranslationKey('messages.message')}
        value={presetMessages[0]}
        defaultOptionsText={getTranslationKey('pleaseSelect')}
        changeCallback={(value) => changeFormHandler('messages', value ? [value] : [])}
        options={messages.map((message) => ({
          value: message.id,
          text: message.title
        }))}
      />
      <OrderWrapper>
        <SelectItem
          options={sortingOptions}
          changeCallback={partial(changeFormHandler, 'sortBy')}
          label={getTranslationKey('collection.defaultSortOrder')}
          value={sortBy}
          margin="0"
          wrapperStyle={{ width: '100%' }}
          className="help_collection_sort"
        />
        <OrderButton
          onClick={() => changeFormHandler('sortOrder', sortOrder === 'asc' ? 'desc' : 'asc')}
        >
          {sortOrder === 'asc' ? <FaSortAlphaDown size={20} /> : <FaSortAlphaUpAlt size={20} />}
        </OrderButton>
      </OrderWrapper>
      <InputItem
        id="galleryExpires-switch-input"
        label={getTranslationKey('collectionShop.expiresIn')}
        pattern={/^[0-9]+$/i}
        min={1}
        value={expiresInDays}
        changeCallback={partial(changeFormHandler, 'expiresInDays')}
        rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
        withTooltip
        tooltipText={getTranslationKey('collectionSettings.expirationDateTooltip')}
      />
      <GalleryTagsInput
        sourceSection="gallery-presets"
        galleryPreset={galleryPresetId}
        emitTags={presetsTagsListener}
      />
    </>
  );
};
