import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Nav,
  NavItem,
  Button
} from 'reactstrap';
import Texts from '../../json/texts.json';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

class DefaultAppHeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  render() {
    const { logoutUser, onlyLogout, whiteLabel, userType } = this.props;

    return onlyLogout ? (
      <Nav className="ml-auto" navbar>
        <Dropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          className="custom-dropdown-position"
        >
          <DropdownToggle nav>
            <img src="assets/img/misc/user.png" className="img-avatar" alt="" />
          </DropdownToggle>
          <DropdownMenu right style={{ right: 'auto' }}>
            <DropdownItem onClick={() => logoutUser(true)}>
              <i className="fa fa-lock" />
              {Texts[window.LANGUAGE_SW].logout}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    ) : (
      <Nav className="ml-auto" navbar>
        {userType === 'SB' && (
          <NavItem className="d-md-down-none pr-3">
            <Button
              color="primary"
              target="_blank"
              href={`${window.API_URL}/api/users/redirect/canny`}
              className="btn-outline"
            >
              <i className="fa fa-icon fa-comments" /> {Texts[window.LANGUAGE_SW].feedback}
            </Button>
          </NavItem>
        )}

        {userType === 'SB' && get(window.Intercom, 'booted') && (
          <Button
            className="mr-3 btn-outline d-sm-down-none"
            color="primary"
            onClick={() => {
              window.Intercom('show');
            }}
          >
            <i className="icon-info" /> {Texts[window.LANGUAGE_SW].helpButton}
          </Button>
        )}

        {!whiteLabel.hideElements.includes('subscription') && (
          <NavItem className="pr-3">
            <Button
              data-cy="UpgradeAccount"
              className="primary-highlight-shadow"
              color="primary"
              href="/#/subscription/pricing"
              onClick={() =>
                this.props.trackEventAction({
                  name: 'clicked-action',
                  payload: {
                    button_id: 'upgrade-account',
                    location: this.props.location.pathname,
                    placement: 'header'
                  }
                })
              }
            >
              <i className="fa fa-icon fa-rocket" />{' '}
              <span className="d-sm-down-none">
                {Texts[window.LANGUAGE_SW].upgradeAccount}
                {' + '}
              </span>
              {Texts[window.LANGUAGE_SW].pricingPage}
            </Button>
          </NavItem>
        )}
      </Nav>
    );
  }
}

function mapStateToProps({
  auth: {
    digistore,
    whiteLabel,
    registrationProcess,
    type,
    subscription: { plan },
    languageSoftware
  }
}) {
  return {
    digistore,
    whiteLabel,
    registrationProcess,
    userType: type,
    userSubscriptionPlan: plan,
    languageSoftware
  };
}

export default withRouter(connect(mapStateToProps, actions)(DefaultAppHeaderDropdown));
