import { IStoreState } from '../types';
import { createSelector } from 'reselect';

export const getSelections = (state: IStoreState) => state.selections;
export const getSelectionByCollectionId = createSelector(
  getSelections,
  (selections) =>
    (collectionID: string): any[] =>
      selections[collectionID]
);

export const getIsSelectionsCleanUpStatus = (state: IStoreState) =>
  state.selectionsNew.isCleanUpFinished;
