import { createAction } from 'redux-act';
import {
  ICreateGlobalShopSettingsParams,
  IFetchOrderListForUserParams,
  IFetchOrderListForUserResponse,
  ICreateProductPriceListParams,
  IGlobalShopSettings,
  IPriceItem,
  IPricingListItem,
  IShopCoupon,
  IUpdateGlobalShopSettingsParams,
  IUpdatePriceValue,
  IUserAccountBalance,
  OrderImageForUser,
  Laboratory,
  ProductGroup,
  IDeleteProductPriceListParams,
  IShopReducerState,
  ISalesConfigBulkUpdateAction,
  IPayDocument,
  ICancelOrderParams,
  IProduct,
  IFetchPricingListAction,
  IPricingGroup,
  IFetchPricingGroupAction,
  IUpdatePricingGroup
} from '../types';

export const fetchProductPricingListAction = createAction();
export const fetchProductPricingListSuccessAction = createAction<IPricingListItem[]>();
export const setSelectedPriceListAction = createAction<string>();

export const createProductPriceListAction = createAction<ICreateProductPriceListParams>();
export const createProductPriceListAction2 = createAction<ICreateProductPriceListParams>();

export const deleteProductPriceListAction = createAction<IDeleteProductPriceListParams>();

export const fetchProductUserPricingGroupsListAction = createAction<IFetchPricingGroupAction>();
export const fetchProductUserPricingGroupsListSuccessAction = createAction<IPricingGroup[]>();

export const updatePricingGroupAction = createAction<IUpdatePricingGroup>();

export const fetchUserPricingAction = createAction<IFetchPricingListAction>();
export const fetchUserPricingSuccessAction = createAction<IPriceItem[]>();

export const updatePriceValueAction = createAction<IUpdatePriceValue>();

export const fetchUserAccountBalanceAction = createAction();
export const fetchUserAccountBalanceSuccessAction = createAction<IUserAccountBalance>();

export const fetchCouponsAction = createAction();
export const fetchCouponsSuccessAction = createAction<IShopCoupon[]>();

export const createCoupon = createAction<IShopCoupon>();
export const updateCoupon = createAction<IShopCoupon>();
export const deleteCoupon = createAction<string>();

export const fetchProductGroupsAction = createAction();
export const fetchProductGroupsSuccessAction = createAction<ProductGroup[]>();

export const fetchProductsAction = createAction();
export const fetchProductsSuccessAction = createAction<IProduct[]>();

export const createGlobalShopSettings = createAction<ICreateGlobalShopSettingsParams>();
export const updateGlobalShopSettings = createAction<IUpdateGlobalShopSettingsParams>();
export const getGlobalShopSettingsForCurrentUser = createAction();
export const getGlobalShopSettingsSuccess = createAction<IGlobalShopSettings | null>();

export const fetchOrderListForUserAction = createAction<IFetchOrderListForUserParams>();
export const fetchOrderListForUserSuccessAction = createAction<IFetchOrderListForUserResponse>();

export const cancelOrderAction = createAction<ICancelOrderParams>();

export const fetchOrderImagesAction = createAction<string>();
export const fetchOrderImagesSuccessAction = createAction<OrderImageForUser[]>();
export const fetchLaboratoriesAction = createAction();
export const fetchLaboratoriesSuccessAction = createAction<Laboratory[]>();

export const setSalesAutomationAction = createAction<IShopReducerState['salesAutomation']>();
export const salesAutomationBulkUpdateAction = createAction<ISalesConfigBulkUpdateAction>();

export const fetchPayDocumentsAction = createAction();
export const setPayDocumentsSuccessAction = createAction<IPayDocument[]>();

export const userHasEnoughInvoicingDataAction = createAction();
export const userHasEnoughInvoicingDataSuccessAction = createAction<boolean>();
