import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeModalState } from '../../modules/actions';
import { getModalsStates, getWatermarksProcessed } from '../../modules/selectors';
import { getTranslationKey } from '../../modules/utils/helpers';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

const Title = styled.div`
  text-align: center;
  font-weight: bolder;
  margin-bottom: 5px;
`;
const ImagesErrorText = styled.div`
  max-height: 200px;
  overflow: auto;
`;
const MutedBlock = styled.div`
  padding: 0 1.25rem 0.75rem;
`;

interface IProgressBar {
  progress: number;
}

const ProgressBar = styled.div<IProgressBar>`
  width: 100%;
  height: 40px;
  border: 1px solid ${theme.commonColors.second};
  margin-bottom: 10px;
  &:after {
    content: '';
    height: 100%;
    width: ${(props) => props.progress + '%'};
    background: ${theme.commonColors.second};
    display: block;
    transition: width 0.2s linear;
  }
`;

export const WatermarkProcessing = () => {
  const dispatch = useDispatch();
  const { watermarkProcessingModal } = useSelector(getModalsStates);
  const { processedWatermarksCount, totalImagesCount, failedImagesNames, failedWatermarksCount } =
    useSelector(getWatermarksProcessed);

  const closeModal = () => {
    dispatch(
      changeModalState({
        key: 'watermarkProcessingModal',
        state: false
      })
    );
  };
  const getProgress = (processedWatermarksCount / totalImagesCount) * 100;

  return (
    <Modal
      centered
      isOpen={watermarkProcessingModal}
      toggle={closeModal}
      wrapClassName="modal-dark"
      size="default"
      data-cy="WatermarkProcessingModal"
    >
      <ModalHeader toggle={closeModal}>
        {getTranslationKey('watermark.watermarkProcessingTitle')}
      </ModalHeader>
      <ModalBody>
        <Title>
          {processedWatermarksCount} / {totalImagesCount}{' '}
          {getTranslationKey('watermark.watermarkProcessingStatus')}
        </Title>
        <ProgressBar progress={getProgress} />
        {failedImagesNames.length ? (
          <>
            <Title>
              {failedWatermarksCount} {getTranslationKey('watermark.watermarkProcessingFailed')}
            </Title>
            <Alert color="primary" className="m-t-10 m-b-20" isOpen>
              <ImagesErrorText>{failedImagesNames.join(', ')}</ImagesErrorText>
            </Alert>
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button block color="success" onClick={closeModal} className="btn btn-primary">
          {getTranslationKey('watermark.watermarkProcessingClose')}
        </Button>
      </ModalFooter>
      <MutedBlock className="muted">
        {' '}
        {getTranslationKey('watermark.watermarkProcessingHint')}{' '}
      </MutedBlock>
    </Modal>
  );
};
