import { createAction } from 'redux-act';
import {
  IStatisticsCollection,
  IStatisticsGraph,
  IStatisticsOverall
} from '../../modules/reducers/statistics';

export const clearStatisticsCollection = createAction();
export const statisticsInitialFetch = createAction();

export const fetchCollectionStatistics = createAction();
export const fetchCollectionStatisticsSuccess = createAction<IStatisticsCollection[]>();
export const fetchCollectionStatisticsMore = createAction();
export const setActivitiesFilter = createAction<string[]>();
export const refreshActivities = createAction();

export const fetchCollectionStatisticsGraph = createAction();
export const fetchCollectionStatisticsGraphSuccess = createAction<IStatisticsGraph[]>();

export const fetchCollectionStatisticsOverall = createAction();
export const fetchCollectionStatisticsOverallSuccess = createAction<IStatisticsOverall>();

export const updateGraphFilter = createAction<string>();
export const setGraphFilter = createAction<string[]>();
