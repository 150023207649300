import { SagaIterator } from 'redux-saga';
import { ISagaAction } from '../../../modules/types';
import { put, select } from 'redux-saga/effects';
import { trackEventAction } from '../../../modules/actions/analytics';
import { getUser } from '../../../modules/selectors';
import { IModalStateChangeAction } from '../../types/modals';

export const showUpgradeModalSaga = function* ({
  payload
}: ISagaAction<IModalStateChangeAction>): SagaIterator {
  if (payload.key === 'upgradeModal' && payload.state) {
    const user = yield select(getUser);
    yield put(
      trackEventAction({
        name: 'plan-limit-reached',
        payload: {
          current_plan: user.subscription.plan,
          user_id: user._id,
          desired_action: payload.desiredAction
        }
      })
    );
  }
};
