import { createSelector } from 'reselect';
import { IStoreState } from '../types';

export const getSlideshow = (state: IStoreState) => state.slideshow.present;
export const getSlideshowUndoRedoState = (state: IStoreState) => state.slideshow;
export const getGoToSlideshowPageStatus = createSelector(
  getSlideshow,
  ({ goToSlideshowPage }) => goToSlideshowPage
);
export const getBackgroundColor = createSelector(
  getSlideshow,
  ({ background }) => background.color
);
export const getSlideshowName = createSelector(getSlideshow, ({ name }) => name);
export const getSlideshowImagesCount = createSelector(
  getSlideshow,
  ({ slides }) => (slides && slides.length) || 0
); // eslint-disable-line
export const getSlideshowImages = createSelector(getSlideshow, ({ slides }) => slides);
export const getIsSlideshowFetched = createSelector(
  getSlideshow,
  ({ isSlidesFetched }) => isSlidesFetched
);
export const getSelectedImagesForSlideshow = createSelector(
  getSlideshow,
  ({ selectedImagesForSlideshow }) => selectedImagesForSlideshow
);
export const getDuplicatesImagesIDS = createSelector(
  getSlideshow,
  ({ selectedImagesForSlideshow, slides }) => {
    const imagesIDS = selectedImagesForSlideshow.map((image) => image.asset.imageId);
    const duplicates: any[] = [];

    slides.forEach((slide) => {
      if (imagesIDS.includes(slide.asset.imageId)) {
        duplicates.push({
          id: slide.asset.imageId,
          src: slide.asset.src
        });
      }
    });

    return duplicates;
  }
);
