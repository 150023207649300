import React from 'react';
import Texts from '../../json/texts.json';
import classnames from 'classnames';

const { pricingTables } = Texts[window.LANGUAGE_SW];

const renderMonthlyPriceYearlyPlan = ({
  intervall,
  discount,
  discountedPriceFirst,
  price,
  currency
}) =>
  intervall === 'yearly' ? (
    <p className="text-muted mb-4">
      {pricingTables.monthlyPrice
        .replace(
          '{{monthlyPrice}}',
          discount ? Math.round(discountedPriceFirst * 12) : Math.round(price * 12)
        )
        .replace('{{currency}}', currency)}
    </p>
  ) : (
    <p className="text-muted mb-4">
      {pricingTables.dailyPrice
        .replace(
          '{{dailyPrice}}',
          discount ? (discountedPriceFirst * 12).toFixed(2) : (price * 12).toFixed(0)
        )
        .replace('{{currency}}', currency)}
    </p>
  );

const renderPerMonthOrYearly = ({ intervall }) =>
  intervall === 'yearly' ? (
    <p className="mb-2">{pricingTables.perYear}</p>
  ) : (
    <p className="mb-2">{pricingTables.perMonths}</p>
  );

const PricingItemTitle = ({ price, plan, currency, discount, intervall, isSB }) => {
  if (discount) {
    var discountedPrice = 0; // eslint-disable-line
    var discountedPriceFirst = 0; // eslint-disable-line
    var months; // eslint-disable-line

    switch (intervall) {
      case 'halfYearly':
        months = 6;
        break;
      case 'yearly':
        months = 12;
        break;
      case 'twoYearly':
        months = 24;
        break;
      default:
        months = 1;
    }

    if (discount[`first_percent_${intervall}`]) {
      discountedPriceFirst = Math.round(price - price * (discount.first_percent / 100)); // eslint-disable-line
      discountedPriceFirst = discountedPriceFirst < 0 ? 0 : discountedPriceFirst; // eslint-disable-line
    }

    if (discount[`first_fixed_${intervall}`]) {
      discountedPriceFirst = Math.round(
        // eslint-disable-line
        (price * months - discount[`first_fixed_${intervall}`]) / months
      );
      discountedPriceFirst = discountedPriceFirst < 0 ? 0 : discountedPriceFirst; // eslint-disable-line
    }

    // if (discountedPrice === 0) discountedPrice = price;
  }

  return (
    <div className="title">
      <h2>{isSB ? pricingTables[`sb_product_name_${plan}_${intervall}`] : pricingTables[plan]}</h2>
      <h2 className={classnames('mb-2', { discount })}>
        <sup>{currency}</sup>
        {price}
        {!discount && isSB && <p className="mb-2">{pricingTables.perMonths}</p>}
      </h2>

      {!discount && intervall === 'yearly' && (
        <p className="mb-2">{pricingTables.yearlySavingsToMonthly}</p>
      )}
      {!discount &&
        renderMonthlyPriceYearlyPlan({
          intervall,
          discount,
          discountedPriceFirst, // eslint-disable-line
          price,
          currency
        })}
      {discount && (
        <div>
          <h2 className="m-b-10">
            <sup>{currency}</sup>
            {/* eslint-disable-next-line */}
            {discountedPriceFirst}
          </h2>
          {isSB && renderPerMonthOrYearly({ intervall })}
          {intervall === 'yearly' && <p className="mb-2">{pricingTables.yearlySavingsToMonthly}</p>}
          {renderMonthlyPriceYearlyPlan({
            intervall,
            discount,
            discountedPriceFirst, // eslint-disable-line
            price,
            currency
          })}
          <small>
            <p className="text-muted">
              {pricingTables.discountedPriceFirst}
              <br />
              {pricingTables.discountedPriceFollowUps
                .replace('$$price$$', discountedPrice) // eslint-disable-line
                .replace('$$currency$$', currency)
                .replace('$$intervall$$', pricingTables[intervall])}
            </p>
          </small>
        </div>
      )}
    </div>
  );
};
export default PricingItemTitle;
