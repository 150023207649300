/* tslint:disable */
import Texts from '../../json/texts.json';
import { IImage } from '../../modules/reducers/images';

export const SORT_IMAGES_OPTIONS = [
  {
    value: 'uploadedAt',
    key: 'gallerySettings.sort.by.uploadedAt'
  },
  {
    value: 'recordedAt',
    key: 'gallerySettings.sort.by.recordedAt'
  },
  {
    value: 'createdAt',
    key: 'gallerySettings.sort.by.createdAt'
  },
  {
    value: 'originalImageName',
    key: 'gallerySettings.sort.by.originalImageName'
  },
  {
    value: 'manualOrder',
    key: 'gallerySettings.sort.by.manualOrder'
  }
];

export const EXPORT_TYPES = [
  {
    value: 'lightroom',
    text: Texts[window.LANGUAGE_SW].exportCollection.exportTypes.lightroom,
    delimiter: ', '
  },
  {
    value: 'captureOnePhotoMech',
    delimiter: ' ',
    text: Texts[window.LANGUAGE_SW].exportCollection.exportTypes.captureOnePhotoMech
  },
  {
    value: 'finderExplorer',
    delimiter: ' OR ',
    text: Texts[window.LANGUAGE_SW].exportCollection.exportTypes.finderExplorer
  }
];

export const IMAGE_FILE_WHITE_LIST = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

export const DEFAULT_IMAGE: IImage = {
  compressionStatus: '',
  gallery_id: '',
  mimeType: '',
  originalImageName: '',
  size: 0,
  status: '',
  _id: '',
  deleteAt: '',
  extension: '',
  height: 0,
  identifier: '',
  imgsFolder: '',
  sizeMb: 0,
  width: 0,
  manualOrder: 0,
  watermarkIdentifier: '',
  isTitleImage: false,
  isSmallScreenTitleImage: false,
  _collection: '',
  _user: '',
  _watermark: '',
  duplicateId: '',
  uploadingStatus: '',
  error: '',
  errorType: '',
  uploadedAt: ''
};
