import { IPricingListItem, Laboratory } from '../modules/types';

export type SelectItem = {
  text: string;
  value: string;
};

const getSelectOptions = (list: IPricingListItem[], laboratories: Laboratory[]) => {
  const defaultList: SelectItem[] = [];
  list.forEach((item) => {
    const laboratory = laboratories.find((lab) => lab.id === item._laboratory);

    defaultList.push({
      text: [laboratory ? laboratory.name + ' | ' : '', item.name].join(''),
      value: item._id
    });
  });
  return defaultList;
};

export default getSelectOptions;
