import dotProp from 'dot-prop-immutable';

export default function (state = {}, action) {
  switch (action.type) {
    case 'UPDATE_WATERMARK_VALUE':
      const watermark = state[action.payload.watermark_id];

      if (watermark) {
        const updatedWatermark = dotProp.set(watermark, action.payload.key, action.payload.value);

        return { ...state, [action.payload.watermark_id]: updatedWatermark };
      }

      return state;

    case 'SET_WATERMARKS':
      const watermarks = {};
      action.payload.forEach((wm) => (watermarks[wm._id] = wm)); // eslint-disable-line

      return { ...state, ...watermarks, watermarks };

    case 'ADD_WATERMARK':
      return {
        ...state,
        [action.payload._id]: action.payload
      };

    case 'REMOVE_WATERMARK':
      return {
        ...state,
        [action.payload]: undefined
      };

    case 'RESET_WATERMARKS_DATA':
      return { ...state.watermarks, watermarks: state.watermarks };

    default:
      return state;
  }
}
