import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { DiscountAlert } from '../../DiscountKey';
import { useHistory } from 'react-router';
import { useUserIsStripeCustomer } from '../../../hooks/useUserIsStripeCustomer';

const UpgradeAccountModal = ({
  isOpen,
  setUpgradeAccountOpenState,
  setModalOpenState,
  modalData
}) => {
  const { addon } = modalData;
  const history = useHistory();
  const userIsStripeCustomer = useUserIsStripeCustomer();

  const onClick = () => {
    setUpgradeAccountOpenState({ key: 'upgradeModal', state: false });
    if (userIsStripeCustomer && addon) {
      setModalOpenState({ key: 'updateAddonsModal', state: true, modalData });
    } else {
      history.push('/subscription/pricing');
    }
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => setUpgradeAccountOpenState({ key: 'upgradeModal', state: !isOpen })}
      wrapClassName="modal-dark"
      size="sm"
    >
      <ModalHeader
        toggle={() => setUpgradeAccountOpenState({ key: 'upgradeModal', state: !isOpen })}
      >
        {Texts[window.LANGUAGE_SW].upgradeAccount}
      </ModalHeader>
      <ModalBody>
        <p>{Texts[window.LANGUAGE_SW].upgradeAccountHint}</p>

        <Button
          data-cy="UpgradeAccountButton"
          className="w-100-p m-b-30"
          color="success"
          onClick={onClick}
        >
          {Texts[window.LANGUAGE_SW].upgradeAccount}
        </Button>

        <DiscountAlert
          data-cy="DiscountAlert"
          utm_medium="upgrade-popup-discount-alert"
          afterClick={() => setUpgradeAccountOpenState({ key: 'upgradeModal', state: false })}
        />
      </ModalBody>
    </Modal>
  );
};

function mapStateToProps({ auth: { digistore }, modals: { upgradeModal, modalData } }) {
  return { isOpen: upgradeModal, digistore, modalData };
}

export default connect(mapStateToProps, actions)(UpgradeAccountModal);
