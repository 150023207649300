import { IStoreState } from '../types';
import { createSelector } from 'reselect';

export const getStatistics = (state: IStoreState) => state.statisticsNew;
export const getStatisticsOverall = createSelector(
  getStatistics,
  (statistics) => statistics.overall
);
export const getStatisticsGraph = createSelector(getStatistics, (statistics) => statistics.graph);
export const getStatisticsGraphFilters = createSelector(
  getStatistics,
  (statistics) => statistics.overallFilter
);
export const getStatisticsCollection = createSelector(
  getStatistics,
  (statistics) => statistics.collection
);
export const getStatisticsActivitiesFilter = createSelector(
  getStatistics,
  (statistics) => statistics.activitiesFilter
);

export const getFilteredActivities = createSelector(
  [getStatisticsCollection, getStatisticsActivitiesFilter],
  (activities, filter) => {
    if (!filter.length) {
      return activities;
    }
    return activities.filter((act) => {
      const actionScope = `${act.action}.${act.scope}`;
      return filter.includes(actionScope);
    });
  }
);
