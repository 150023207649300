interface IMediaPoints {
  desktop: string;
  desktop_min: string;
  mobile: string;
}

export const media: IMediaPoints = {
  desktop: '1000px',
  desktop_min: '768px',
  mobile: '684px'
};

export default media;
