import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export default ({
  input,
  field: { placeholder, type, title, tooltip_id, tooltip_text },
  meta: { touched, error },
  disabled
}) => (
  <div data-cy="form-group" className="form-group">
    {title && (
      <label className="form-control-label font-weight-bold">
        {title}{' '}
        {tooltip_id && tooltip_text && (
          <i id={tooltip_id} className="m-l-5 fa fa-icon fa-question-circle" />
        )}
      </label>
    )}
    {type === 'textarea' ? (
      <textarea
        {...input}
        placeholder={placeholder}
        className={touched && error ? 'form-control is-invalid' : 'form-control'}
        disabled={disabled}
      />
    ) : (
      <input
        data-cy="input"
        {...input}
        placeholder={placeholder}
        type={type}
        className={touched && error ? 'form-control is-invalid' : 'form-control'}
        disabled={disabled}
      />
    )}

    {touched &&
      error &&
      error.map((error, i) => (
        <div key={i} data-cy="invalid-feedback" className="invalid-feedback">
          {error}
        </div>
      ))}
    {tooltip_id && tooltip_text && (
      <UncontrolledTooltip placement="right" target={tooltip_id}>
        {tooltip_text}
      </UncontrolledTooltip>
    )}
  </div>
);
