import { ISagaAction, ITrackEventAction } from '../../types';
import Api from '../../utils/API';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export const trackEventSaga = function* (action: ISagaAction<ITrackEventAction>): SagaIterator {
  try {
    // @ts-ignore
    yield call(Api.Analytics.trackEvent, {
      name: action.payload.name,
      payload: action.payload.payload,
      app: 'builder',
      timestamp: new Date(),
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    });
  } catch (e) {
    console.error('Failed to track event', e);
  }
};
