import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getModalsStates } from '../../modules/selectors';
import { changeModalState, setAppVersionDelay } from '../../modules/actions';
import { CHECKSUMS_INTERVAL_NEXT } from '../../modules/constants';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    flex-basis: 49%;
  }
`;

export const AppVersionModal = () => {
  const dispatch = useDispatch();
  const { appVersionModal } = useSelector(getModalsStates);
  const closeModal = () => {
    dispatch(
      changeModalState({
        key: 'appVersionModal',
        state: false
      })
    );
    dispatch(setAppVersionDelay(CHECKSUMS_INTERVAL_NEXT));
  };

  const reloadApp = () => window.location.reload();

  return (
    <Modal
      data-cy="appVersionModal"
      isOpen={appVersionModal}
      toggle={closeModal}
      centered
      wrapClassName="modal-dark"
      size="default"
    >
      <ModalHeader data-cy="appVersionModalHeader" toggle={closeModal}>
        {getTranslationKey('appVersionModal.title')}
      </ModalHeader>
      <ModalBody>{getTranslationKey('appVersionModal.text')}</ModalBody>
      <ModalFooter>
        <ButtonsWrapper>
          <Button color="success" onClick={closeModal} className="btn btn-outline">
            {getTranslationKey('appVersionModal.dismiss')}
          </Button>
          <Button color="success" onClick={reloadApp} className="btn btn-primary">
            {getTranslationKey('appVersionModal.confirm')}
          </Button>
        </ButtonsWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default AppVersionModal;
