import { createReducer } from 'redux-act';
import {
  clearStatisticsCollection,
  fetchCollectionStatisticsGraphSuccess,
  fetchCollectionStatisticsOverallSuccess,
  fetchCollectionStatisticsSuccess,
  setActivitiesFilter,
  setGraphFilter
} from '../../../modules/actions';
import { DEFAULT_OVERALL } from '../../../modules/constants/statistics';
import { IGallery } from '../../../modules/reducers/galleries';

export interface IStatisticsGraph {
  downloads: number;
  views: number;
  appInstalls: number;
  shares: number;
  clicks: number;
  contactDataViews: number;
  date: string;
}

export interface IStatisticsOverall {
  _id: string;
  singleDownloads: number;
  zipDownloads: number;
  collectionViews: number;
  shareViews: number;
  appViews: number;
  appInstalls: number;
  shares: number;
  socialClicks: number;
  websiteClicks: number;
  telephoneClicks: number;
  emailClicks: number;
  contactDataViews: number;
}

export interface IStatisticsCollection {
  _id: string;
  action: string;
  scope: string;
  actionData: string;
  _gallery: IGallery;
  _slideshow?: string;
  payload?: { [key: string]: any };
  createdAt: string;
  _image?: {
    _id: string;
    originalImageName: string;
  };
}

export interface IStatisticsReducerState {
  collection: IStatisticsCollection[];
  graph: IStatisticsGraph[];
  overall: IStatisticsOverall;
  activitiesFilter: string[];
  overallFilter: string[];
}

const initialState: IStatisticsReducerState = {
  collection: [],
  graph: [],
  overall: DEFAULT_OVERALL,
  activitiesFilter: [],
  overallFilter: ['views', 'shares']
};

export const statisticsNew = createReducer<IStatisticsReducerState>({}, initialState);

statisticsNew.on(
  fetchCollectionStatisticsSuccess,
  (state: IStatisticsReducerState, payload: IStatisticsCollection[]) => ({
    ...state,
    collection: payload
  })
);
statisticsNew.on(
  fetchCollectionStatisticsOverallSuccess,
  (state: IStatisticsReducerState, payload: IStatisticsOverall) => ({
    ...state,
    overall: payload
  })
);
statisticsNew.on(
  fetchCollectionStatisticsGraphSuccess,
  (state: IStatisticsReducerState, payload: IStatisticsGraph[]) => ({
    ...state,
    graph: payload
  })
);
statisticsNew.on(setGraphFilter, (state: IStatisticsReducerState, payload: string[]) => ({
  ...state,
  overallFilter: payload
}));
statisticsNew.on(setActivitiesFilter, (state: IStatisticsReducerState, payload: string[]) => ({
  ...state,
  activitiesFilter: payload
}));
statisticsNew.on(clearStatisticsCollection, () => initialState);
