import { createReducer } from 'redux-act';
import { fetchDomainsFailed, fetchDomainsSuccess } from '../../../modules/actions';

export interface IDomain {
  cname: string;
  created_at: string;
  hostname: string;
  imprint: string;
  privacy: string;
  updated_at: string;
  __v: number;
  _id: string;
  _user: string;
}

export interface IDomainsReducerState {
  items: IDomain[];
  isFetched: boolean;
}

const initialState: IDomainsReducerState = {
  items: [],
  isFetched: false
};

export const domainsNew = createReducer<IDomainsReducerState>({}, initialState);
domainsNew.on(fetchDomainsSuccess, (state: IDomainsReducerState, payload: IDomain[]) => ({
  ...state,
  items: payload,
  isFetched: true
}));
domainsNew.on(fetchDomainsFailed, () => initialState);
