import React from 'react';
import { getDataList } from './getDataList';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../modules/selectors/user';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { Link, useHistory } from 'react-router-dom';
import { getAccessRules } from '../../../modules/selectors/accessRules';
import theme from '../../../assets/css/theme';
import styled from 'styled-components';
import _get from 'lodash/get';
import { Progress } from 'reactstrap';
import useTrackClick from '../../../hooks/tracking/use-track-click';
import { getTranslationKey } from '../../../modules/utils';
import { CURRENCIES_SYMBOLS, SHOP_CURRENCIES } from '../../../modules/types';
import { setModalOpenState } from '../../../actions';
import { useUserIsStripeCustomer } from '../../../hooks/useUserIsStripeCustomer';
import { useIsPaymentProblem } from '../../../common/use-is-payment-problem';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
`;

const ListItem = styled.li`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  span {
    margin-left: 12px;
  }
`;

const LinkTitle = styled(Link)`
  white-space: nowrap;
`;

const StyledProgress = styled(Progress)`
  position: relative;
  margin-top: 5px;
`;

const ProgressContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: black;
`;

const messagesForFeaturesAboveLimits: {
  [key: string]: (amountAboveLimit: number) => string;
} = {
  imageStorageMb: () =>
    getTranslationKey('subscription.featuresDowngrade.image-storage-above-limit'),
  videoStorageMb: () =>
    getTranslationKey('subscription.featuresDowngrade.video-storage-above-limit'),
  collections: (amountToDelete: number) =>
    getTranslationKey('subscription.featuresDowngrade.collections-above-limit', {
      amountToDelete
    }),
  slideshows: (amountToDelete: number) =>
    getTranslationKey('subscription.featuresDowngrade.slideshows-above-limit', {
      amountToDelete
    }),
  brands: (amountToDelete: number) =>
    getTranslationKey('subscription.featuresDowngrade.brands-above-limit', {
      amountToDelete
    }),
  domains: (amountToDelete: number) =>
    getTranslationKey('subscription.featuresDowngrade.domains-above-limit', {
      amountToDelete
    }),
  collectionPresets: (amountToDelete: number) =>
    getTranslationKey('subscription.featuresDowngrade.presets-above-limit', {
      amountToDelete
    }),
  customWatermarks: () =>
    getTranslationKey('subscription.featuresDowngrade.watermarks-above-limit'),
  customDesign: () => getTranslationKey('subscription.featuresDowngrade.design-above-limit')
};
interface UsageProps {
  showOnlyFeaturesAboveLimits?: boolean;
  showMessageUnderFeaturesAboveLimits?: boolean;
  onUpgradeLinkClick?: (id: string) => void;
}

const Usage = ({
  showOnlyFeaturesAboveLimits = false,
  showMessageUnderFeaturesAboveLimits = false,
  onUpgradeLinkClick
}: UsageProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const trackClick = useTrackClick();
  const user = useSelector(getUser);
  const userIsStripeCustomer = useUserIsStripeCustomer();
  const accessRules = useSelector(getAccessRules);
  const { isPaymentProblem } = useIsPaymentProblem();
  const percent = _get(user, 'serviceFee.percent', '');
  const fixed = _get(user, 'serviceFee.fixed', '');
  const feeData = {
    percent,
    currencySymbol: CURRENCIES_SYMBOLS[user.currency as SHOP_CURRENCIES],
    fixed: Math.floor(fixed / 100)
  };
  let dataList = getDataList(accessRules, feeData, isPaymentProblem);
  if (showOnlyFeaturesAboveLimits) {
    dataList = dataList.filter((item) => item.isAboveLimit);
  }

  const openAddonUpgradeModal = (id: string) => {
    const addon = dataList.find((item) => item.id === id);

    if (addon.upgradeAddonModalKey && userIsStripeCustomer) {
      dispatch(
        setModalOpenState({
          key: 'updateAddonsModal',
          state: true,
          modalData: {
            addon: addon.upgradeAddonModalKey
          }
        })
      );
    } else {
      history.push('/subscription/pricing');
    }
  };
  const trackUpgradeLinkClick = (id: string) => {
    trackClick('upgrade-for-more', { feature_id: id });
    openAddonUpgradeModal(id);
  };

  if (onUpgradeLinkClick) {
    const customOnUpgradeLinkClick = onUpgradeLinkClick;
    onUpgradeLinkClick = (id: string) => {
      trackUpgradeLinkClick(id);
      customOnUpgradeLinkClick(id);
    };
  } else {
    onUpgradeLinkClick = trackUpgradeLinkClick;
  }

  return (
    <List>
      {dataList.map(
        (
          { id, title, content, linkTitle, isAvailable, isAboveLimit, amountAboveLimit, progress },
          idx
        ) => (
          <ListItem key={idx}>
            <Header>
              <Title>
                {isAvailable && <FaCheck size={18} color={theme.commonColors.second} />}
                {!isAvailable && <ImCross size={18} color={theme.buttonsColors.danger} />}
                <span>{title}</span>
              </Title>
              {linkTitle && (
                <LinkTitle data-cy="LinkTitle" to="#" onClick={() => onUpgradeLinkClick(id)}>
                  {linkTitle}
                </LinkTitle>
              )}
            </Header>
            {content && (
              <StyledProgress color="success" value={progress}>
                <ProgressContent data-cy="ProgressContent">{content}</ProgressContent>
              </StyledProgress>
            )}
            {isAboveLimit &&
              showMessageUnderFeaturesAboveLimits &&
              messagesForFeaturesAboveLimits[id] &&
              messagesForFeaturesAboveLimits[id](amountAboveLimit)}
          </ListItem>
        )
      )}
    </List>
  );
};

export default Usage;
