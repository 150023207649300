import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SelectItem } from '../Forms/SelectItem';
import { WatermarkPreview } from '../WatermarkPreview';
import { useSelector } from 'react-redux';
import { getWatermarksList } from '../../modules/selectors';
import { NavLink } from 'react-router-dom';
import theme from '../../assets/css/theme';
import { compact, find, values } from 'lodash';
import { getTranslationKey } from '../../modules/utils';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;
const LinkToBrand = styled(NavLink)`
  color: ${theme.textColors.link};
  cursor: pointer;
  margin-top: 10px;
`;

interface IProps {
  watermark: string;
  changeCallback: (value: string) => void;
  label?: string;
}

export const WatermarkSelection = ({ watermark, changeCallback, label = '' }: IProps) => {
  const watermarksList = values(useSelector(getWatermarksList));
  const options = compact(watermarksList).map((item) => ({
    value: item._id,
    text: item.description
  }));

  const [selectedWatermark, setSelectedWatermark] = useState(null);

  useEffect(() => {
    if (watermark) {
      const findWatermark = find(watermarksList, { _id: watermark });
      setSelectedWatermark(findWatermark);
    }
  }, [watermark, watermarksList]);

  return (
    <Wrapper>
      <SelectItem
        data-cy="Select-watermark"
        defaultOptionsText={getTranslationKey('watermark.no-watermark')}
        options={options}
        changeCallback={changeCallback}
        value={watermark}
        label={label}
      />
      {selectedWatermark && <WatermarkPreview watermark={selectedWatermark} />}
      <LinkToBrand to="/settings" target="_blank">
        {getTranslationKey('watermark.add')}
      </LinkToBrand>
    </Wrapper>
  );
};
