import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as actions from '../../actions';
import Texts from '../../json/texts.json';
import Imprint from './Imprint';
import Privacy from './Privacy';
import { FaPlus } from 'react-icons/fa';
import theme from '../../assets/css/theme';

class NewDomain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hostname: '',
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.createDomain = this.createDomain.bind(this);
    this.onChangeDomain = this.onChangeDomain.bind(this);
    this.onSelectedImprintChanged = this.onSelectedImprintChanged.bind(this);
    this.onSelectedPrivacyChanged = this.onSelectedPrivacyChanged.bind(this);
  }

  onSelectedImprintChanged(imprint) {
    this.setState({ imprint });
  }

  onSelectedPrivacyChanged(privacy) {
    this.setState({ privacy });
  }

  onChangeDomain(e) {
    this.setState({ hostname: e.target.value });
  }

  toggle() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  createDomain() {
    this.props.createDomain(this.state, this.props.notify);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      imprint: null,
      hostname: '',
      privacy: null
    }));
  }

  render() {
    const { auth, setUpgradeAccountOpenState, availableRules = {} } = this.props;

    return (
      <div className="inline">
        <button
          className="btn btn-light w-100-p create-new-button"
          onClick={() => {
            const freePlan = auth.type === 'SB' && auth.subscription.plan === 'free'; // eslint-disable-line
            const noDomainsLeft = availableRules.domainsLeft === 0; // eslint-disable-line

            if (freePlan || noDomainsLeft) {
              return setUpgradeAccountOpenState({
                key: 'upgradeModal',
                state: true,
                desiredAction: 'domains',
                modalData: { addon: 'domains' }
              });
            }

            this.toggle();
          }}
        >
          <FaPlus
            size={15}
            color={theme.commonColors.second}
            data-cy="AddSubdomain"
            className="plus-icon mr-2"
          />
          {Texts[window.LANGUAGE_SW].customDomains.newDomain}
        </button>
        <Modal
          centered
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          wrapClassName="modal-primary"
          size="default"
          onKeyPress={this.handleCreateKeyPress}
        >
          <ModalHeader toggle={this.toggle}>
            {Texts[window.LANGUAGE_SW].customDomains.newDomain}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <b>{Texts[window.LANGUAGE_SW].customDomains.yourSubDomain}</b>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={Texts[window.LANGUAGE_SW].customDomains.placeholderNewDomain}
                    value={this.state.hostname}
                    onChange={this.onChangeDomain}
                  />
                </div>
                <b>{Texts[window.LANGUAGE_SW].customDomains.imprint}</b>
                <Imprint onSelectedImprintChanged={this.onSelectedImprintChanged} />
                <b>{Texts[window.LANGUAGE_SW].customDomains.privacy}</b>
                <Privacy onSelectedPrivacyChanged={this.onSelectedPrivacyChanged} />
                {auth.type === 'SB' && (
                  <a
                    href="https://support.scrappbook.de/de/articles/61600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {Texts[window.LANGUAGE_SW].customDomains.howTo}
                  </a>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger pull-left"
              onClick={() => this.setState((prevState) => ({ isOpen: !prevState.isOpen }))}
            >
              {Texts[window.LANGUAGE_SW].abort}
            </button>
            <button
              className="btn btn-primary"
              disabled={!this.state.hostname || !this.state.imprint || !this.state.privacy}
              onClick={this.createDomain}
            >
              {Texts[window.LANGUAGE_SW].create}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth, notify, accessRules: { availableRules } }) {
  return { auth, notify, availableRules };
}
export default connect(mapStateToProps, actions)(NewDomain);
