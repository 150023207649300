import { createReducer } from 'redux-act';
import {
  changeCollectionStateSuccess,
  clearCollection,
  fetchCollectionFailed,
  fetchCollectionSuccess
} from '../../../modules/actions/collections';
import { IShopSalesAutomation, IGalleryTag } from '../../../modules/types';
import {
  COLLECTION_SHOP_AUTOMATION_DEFAULTS,
  DEFAULT_SETTINGS_TAB
} from '../../../modules/constants';

interface ICollectionButtonStyle {
  backgroundColor: string;
  color: string;
}

interface ICollectionFont {
  fontFamily: string;
  name: string;
}

interface ICollectionSharing {
  show: boolean;
}

interface ICollectionStyles {
  backgroundColor: string;
}

export interface ICustomDomain {
  domain: string;
  path: string;
  url: string;
}

export interface IEndCustomer {
  user: string;
  type: string;
  email: string;
  language: number;
  name: string;
  firstName?: string;
  lastName?: string;
  marketingEmailsEnabled?: boolean;
  unsubscribedFromMarketingEmails?: boolean;
  endCustomerNumber?: string;
  telephoneNumber?: string;
  sevDeskId?: string;
  _country?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  password?: string;
  createdAt: Date;
  updatedAt: Date;
  hiddenImages?: string[];
  id?: string;
  [key: string]: string | number | Date | string[] | boolean;
}

export interface ICollectionsReducerState {
  readonly buttonStyle: ICollectionButtonStyle;
  readonly caching: number;
  readonly comment: boolean;
  readonly created_at: string;
  customDomain: ICustomDomain;
  readonly deleted: number;
  readonly description: string;
  readonly download: boolean;
  downloadPin: string;
  readonly downloadPinActive: boolean;
  eventDate: string;
  readonly font: ICollectionFont;
  readonly images: any[];
  readonly imgsFolder: string;
  readonly isLR: boolean;
  readonly isStudio: boolean;
  readonly language: number;
  readonly like: boolean;
  readonly mjMessageId: any[];
  name: string;
  readonly responsiveImages: boolean;
  readonly sharing: ICollectionSharing;
  readonly showAppInstall: boolean;
  showImageCaptionInGallery: boolean;
  readonly singleImageDownloadDisabled: boolean;
  readonly statistic: string;
  readonly styles: ICollectionStyles;
  readonly templateName: string;
  readonly signedWilCardUrl: string;
  title: string;
  type: string;
  readonly _galleryPreset: string;
  readonly updated_at: string;
  readonly userBrand: string;
  readonly __v: number;
  readonly _id: string;
  readonly _user: any;
  password: string;
  globalDownloadState: boolean;
  globalWatermarkID: string;
  messages?: string[];
  galleryStyle: string;
  activeTab: string;
  shopActive: boolean;
  showOnlyPurchasedPrints: boolean;
  _productPricingList: string;
  salesAutomationConfig: IShopSalesAutomation;
  shopCtaPlacement: string;
  design: {
    disableDecorations: boolean;
    showTrademark?: boolean;
    loadFonts: string[];
    imageSpacing: string;
    variables: {
      [key: string]: string;
    };
  };
  digitalShop: {
    enabled: boolean;
    manualOrderProcessing: boolean;
    digitalPricingList: string | null;
    alreadyPurchasedTier: string | null;
  };
  receiver?: string;
  security: {
    wrongPasswordTries: number | null;
    passwordTriesLockedUntil: Date | null;
  };
  tags?: IGalleryTag[];
  expirationDate?: Date | null;
  mainCustomers?: IEndCustomer[];
}

const initialState: ICollectionsReducerState = {
  buttonStyle: {
    backgroundColor: '',
    color: ''
  },
  caching: 1,
  comment: false,
  created_at: '',
  customDomain: {
    domain: '',
    path: '',
    url: ''
  },
  deleted: 0,
  description: '',
  download: true,
  downloadPin: '',
  downloadPinActive: false,
  eventDate: '',
  font: {
    fontFamily: '',
    name: ''
  },
  signedWilCardUrl: '',
  images: [],
  imgsFolder: '',
  _galleryPreset: '',
  isLR: false,
  isStudio: true,
  language: 1,
  like: true,
  mjMessageId: [],
  name: '',
  responsiveImages: true,
  sharing: {
    show: true
  },
  showAppInstall: true,
  showImageCaptionInGallery: false,
  singleImageDownloadDisabled: false,
  showOnlyPurchasedPrints: false,
  statistic: '',
  styles: {
    backgroundColor: ''
  },
  templateName: '',
  title: '',
  type: '',
  updated_at: '',
  userBrand: '',
  password: '',
  __v: 0,
  _id: '',
  _user: {},
  globalDownloadState: false,
  globalWatermarkID: '',
  galleryStyle: 'justified',
  activeTab: localStorage.getItem('collection:settings:lastTab') || DEFAULT_SETTINGS_TAB,
  shopActive: false,
  _productPricingList: '',
  salesAutomationConfig: COLLECTION_SHOP_AUTOMATION_DEFAULTS,
  shopCtaPlacement: 'after-last',
  design: {
    disableDecorations: false,
    showTrademark: false,
    loadFonts: [],
    imageSpacing: '',
    variables: {
      font: '',
      primaryColor: '',
      secondaryColor: ''
    }
  },
  digitalShop: {
    enabled: false,
    manualOrderProcessing: false,
    digitalPricingList: null,
    alreadyPurchasedTier: null
  },
  security: {
    wrongPasswordTries: null,
    passwordTriesLockedUntil: null
  },
  tags: [],
  messages: [],
  expirationDate: null
};
export const collectionNew = createReducer<ICollectionsReducerState>({}, initialState);
collectionNew.on(
  fetchCollectionSuccess,
  (state: ICollectionsReducerState, payload: ICollectionsReducerState) => ({
    ...state,
    ...payload,
    design: payload.design
      ? {
          ...payload.design,
          // @ts-ignore
          loadFonts: payload.design.loadFonts.map((font) => font.name)
        }
      : {
          loadFonts: [],
          variables: {},
          disableDecorations: false,
          imageSpacing: ''
        }
  })
);
collectionNew.on(
  changeCollectionStateSuccess,
  (state: ICollectionsReducerState, payload: Partial<ICollectionsReducerState>) => ({
    ...state,
    ...payload
  })
);
collectionNew.on(fetchCollectionFailed, () => initialState);
collectionNew.on(clearCollection, () => initialState);
