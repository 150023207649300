import { createSelector } from 'reselect';
import { IStoreState } from '../types';

export const getDraggableSlideState = (state: IStoreState) => state.draggableSlideState;
export const getSelectedSlidesLength = createSelector(
  getDraggableSlideState,
  ({ selectedSlides }) => selectedSlides.length
);
export const getSelectedSlidesIDS = createSelector(getDraggableSlideState, ({ selectedSlides }) =>
  selectedSlides.map((slide) => (slide.slideId ? slide.slideId : slide._id))
);
