import React from 'react';
import Loadable from 'react-loadable';
import Texts from './json/texts.json';
import { Settings } from './pages/Settings';
import WhiteLabelLayout from './containers/WhiteLabelLayout';
import SubscriptionPayment from './pages/subscription/SubscriptionPayment';

function Loading() {
  return (
    <div className="text-center">
      <div className="loader" />
    </div>
  );
}

const UserDashboard = Loadable({
  loader: () => import('./views/User/Dashboard'),
  loading: Loading
});

const AdminDashboard = Loadable({
  loader: () => import('./views/Admin/Dashboard'),
  loading: Loading
});

const AdminUserView = Loadable({
  loader: () => import('./elements/Admin/User'),
  loading: Loading
});

const CollectionsOverview = Loadable({
  loader: () => import('./pages/Collections'),
  loading: Loading
});

const CollectionEdit = Loadable({
  loader: () => import('./pages/CollectionEdit'),
  loading: Loading
});

const Portfolios = Loadable({
  loader: () => import('./pages/Portfolios'),
  loading: Loading
});

const PortfolioEdit = Loadable({
  loader: () => import('./pages/PortfolioEdit'),
  loading: Loading
});

const Slideshow = Loadable({
  loader: () => import('./pages/collectionEdit/slideshow/Slideshow'),
  loading: Loading
});

// const CommingSoon = Loadable({
//   loader: () => import('./views/CommingSoon'),
//   loading: Loading
// });

const Account = Loadable({
  loader: () => import('./views/User/Account'),
  loading: Loading
});

// const CustomDomain = Loadable({
//   loader: () => import('./views/User/CustomDomain'),
//   loading: Loading
// });

const Pricing = Loadable({
  loader: () => import('./views/Pricing/ScrAppBookPricing'),
  loading: Loading
});

const Subscription = Loadable({
  loader: () => import('./pages/Subscription'),
  loading: Loading
});

// const Brand = Loadable({
//   loader: () => import('./views/Brand'),
//   loading: Loading
// });

// const Watermark = Loadable({
//   loader: () => import('./views/Watermark'),
//   loading: Loading
// });

// const Language = Loadable({
//   loader: () => import('./views/Language'),
//   loading: Loading
// });

// const Notification = Loadable({
//   loader: () => import('./views/Notification'),
//   loading: Loading
// });

const Referral = Loadable({
  loader: () => import('./views/Subscription/Referral'),
  loading: Loading
});

const CollectionPresets = Loadable({
  loader: () => import('./pages/CollectionPresets'),
  loading: Loading
});

const Shop = Loadable({
  loader: () => import('./pages/Shop'),
  loading: Loading
});
// TODO: remove after testing
const ShopPricing = Loadable({
  loader: () => import('./pages/ShopPricing'),
  loading: Loading
});
// TODO: remove after testing
const ShopDigitalPricing = Loadable({
  loader: () => import('./pages/ShopDigitalPricing'),
  loading: Loading
});

const newShopPricing = Loadable({
  loader: () => import('./pages/newShowPricing/ShopPricing'),
  loading: Loading
});

const ShopCoupons = Loadable({
  loader: () => import('./pages/ShopCoupons'),
  loading: Loading
});

const ShopOrders = Loadable({
  loader: () => import('./pages/ShopOrders'),
  loading: Loading
});

const OverviewTab = Loadable({
  loader: () => import('./pages/OverviewTab'),
  loading: Loading
});

const SalesAutomation = Loadable({
  loader: () => import('./pages/SalesAutomation'),
  loading: Loading
});

// const TaxSettings = Loadable({
//   loader: () => import('./pages/TaxSettings'),
//   loading: Loading
// });

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const getWhiteLabelRoutes = (language) => [
  {
    id: 'home',
    path: '/',
    exact: true,
    name: 'Home',
    component: WhiteLabelLayout
  },
  {
    id: 'referral',
    path: '/referral',
    name: Texts[language].referralProgram.menu,
    component: Referral,
    exact: true
  },
  {
    path: '/dashboard',
    id: 'dashboard',
    name: Texts[language].userDashboard.title,
    component: UserDashboard,
    exact: true
  },
  {
    path: '/admin/dashboard',
    id: 'admin-dashboard',
    name: Texts[language].adminDashboard.title,
    component: AdminDashboard,
    exact: true
  },
  {
    path: '/admin/user/show/:id',
    id: 'admin-user-view',
    name: Texts[language].adminDashboard.user,
    component: AdminUserView
  },
  {
    path: '/collections',
    id: 'collections',
    name: Texts[language].myCollectionsShort,
    component: CollectionsOverview,
    exact: true
  },
  {
    id: 'collection-edit',
    path: '/collection/edit/:collection_id/:tab?',
    name: Texts[language].editGallery,
    component: CollectionEdit,
    exact: true
  },
  {
    id: 'collection-edit-slideshow',
    path: '/collection/edit/:collection_id/:tab?/slideshow/:slideshow_id',
    name: Texts[language].slideshow.heading,
    component: Slideshow,
    exact: true
  },
  {
    id: 'portfolios',
    path: '/portfolios',
    name: Texts[language].portfolio.portfolios,
    component: Portfolios,
    exact: true
  },
  {
    id: 'portfolio-edit',
    path: '/portfolios/edit/:portfolio_id?',
    name: Texts[language].portfolio.title,
    component: PortfolioEdit,
    exact: true
  },
  {
    id: 'gallery-presets',
    path: '/gallerypresets',
    exact: true,
    name: Texts[language].galleryPresets.route,
    component: CollectionPresets
  },
  {
    id: 'settings',
    path: '/settings',
    exact: true,
    name: Texts[language].settings,
    component: Settings
  },
  {
    id: 'account',
    path: '/account',
    exact: true,
    name: Texts[language].account,
    component: Account
  },
  // {
  //   id: 'subscription',
  //   path: '/subscription',
  //   exact: true,
  //   name: Texts[language].subscription.route,
  //   component: Account
  // },
  {
    id: 'subscription-pricing-old',
    path: '/subscription/pricing/old',
    exact: true,
    name: Texts[language].pricingPage,
    component: Pricing
  },
  {
    id: 'subscription-pricing',
    path: '/subscription/pricing',
    exact: true,
    name: Texts[language].pricingPage,
    component: Subscription
  },
  {
    id: 'subscription-payment',
    path: '/subscription/pricing/payment',
    exact: true,
    name: Texts[language].paymentPage,
    component: SubscriptionPayment
  },
  {
    id: 'shop',
    path: '/shop',
    exact: true,
    name: Texts[language].shop.shopMenuLabel,
    component: Shop
  },
  {
    id: 'shop-prices',
    path: '/shop/newPrices', // todo change to component
    exact: true,
    name: Texts[language].shop.priceMenuLabel,
    component: newShopPricing
  },
  {
    id: 'shop-prices', // TODO: remove after testing also remove texts labels
    path: '/shop/prices',
    exact: true,
    name: Texts[language].shop.priceMenuLabelOld,
    component: ShopPricing
  },
  {
    id: 'digital-pricing-list',
    path: '/shop/digital-pricing-list',
    exact: true,
    name: Texts[language].shop.digitalPricingListLabel,
    component: ShopDigitalPricing
  },
  {
    id: 'shop-sales-automation',
    path: '/shop/sales-automation',
    exact: true,
    name: Texts[language].shop['sales-automation'].title,
    component: SalesAutomation
  },
  {
    id: 'coupons',
    path: '/shop/coupons',
    exact: true,
    name: Texts[language].shop.couponsMenuLabel,
    component: ShopCoupons
  },
  {
    id: 'orders',
    path: '/shop/orders',
    exact: true,
    name: Texts[language].shop.ordersMenuLabel,
    component: ShopOrders
  },
  {
    id: 'subscription-overview',
    path: '/subscription',
    exact: true,
    name: Texts[language].overview,
    component: OverviewTab
  }
];

export default getWhiteLabelRoutes;
