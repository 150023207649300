import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Row } from 'reactstrap';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';
import EditWatermarkData from './EditWatermarkData';
import EditWatermarkPreview from './EditWatermarkPreview';

class EditWatermark extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.save = this.save.bind(this);
  }

  async save() {
    const { updateWatermark, notify, watermark, toggle } = this.props;
    this.setState({ saving: true });
    const success = await updateWatermark(watermark, notify);
    success && toggle();
    this.setState({ saving: false });
  }

  render() {
    const { isOpen, watermark, toggle } = this.props;
    if (!watermark) return null;

    return (
      <Modal
        data-cy="EditWatermarkModal"
        centered
        isOpen={isOpen}
        toggle={toggle}
        wrapClassName="modal-dark"
        size={
          watermark.type === 'text' || (watermark.type === 'image' && watermark.watermarkPublicKey)
            ? 'xl'
            : 'md'
        }
      >
        <ModalHeader toggle={toggle}>{Texts[window.LANGUAGE_SW].watermark.edit}</ModalHeader>
        <ModalBody>
          <Row>
            <EditWatermarkPreview watermark={watermark} />
            {(watermark.type === 'text' ||
              (watermark.type === 'image' && watermark.watermarkPublicKey)) && (
              <EditWatermarkData watermark={watermark} />
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.save} color="success" disabled={this.state.saving}>
            {Texts[window.LANGUAGE_SW].save}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps({ notify }) {
  return { notify };
}
export default connect(mapStateToProps, actions)(EditWatermark);
