import { createReducer } from 'redux-act';
import { selectionCleanUpSuccess } from '../../../modules/actions';

export interface ISelectionsReducerState {
  isCleanUpFinished: boolean;
}

const initialState: ISelectionsReducerState = {
  isCleanUpFinished: true
};

export const selectionsNew = createReducer<ISelectionsReducerState>({}, initialState);
selectionsNew.on(selectionCleanUpSuccess, (state: ISelectionsReducerState, payload: boolean) => ({
  ...state,
  isCleanUpFinished: payload
}));
