import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import {
  errorsGlobalError,
  fetchEmailPackages,
  fetchEmailTemplatesSuccessAction,
  fetchRecipientsSuccessAction,
  getEmailTemplatePreviewSuccessAction,
  setDeliveryFormAction,
  setSelectedEmailTemplateAction,
  showNotifyFail,
  showNotifySuccess
} from '../../actions';
import { ISagaAction } from '../../types';
import {
  IEmailTemplate,
  IFetchEmailTemplatesParams,
  ISendEmailsAction,
  ISendLoginLinkEmail
} from '../../types/emails';
import { Api, getTranslationKey } from '../../utils';
import ApiErrors from '../../utils/API/APIErrors';
import {
  getCollectionId,
  getCollectionsNew,
  getDeliveryFormSelector,
  getEmailTemplatesSelector
} from '../../selectors';
import { emailTemplatesInitialState } from '../../reducers';
import { omit } from 'lodash';
import { DEFAULT_EMAIL_TEMPLATE } from '../../constants/email_templates';

export const fetchEmailTemplatesSaga = function* ({
  payload
}: ISagaAction<IFetchEmailTemplatesParams>): SagaIterator {
  try {
    const response = yield call(Api.Emails.fetchEmailTemplates, payload);
    ApiErrors.checkOnApiError(response);
    yield put(fetchEmailTemplatesSuccessAction(response.result.list));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createEmailTemplateSaga = function* ({
  payload
}: ISagaAction<IEmailTemplate>): SagaIterator {
  try {
    const emailTemplates = yield select(getEmailTemplatesSelector);
    const response = yield call(
      Api.Emails.create,
      omit(payload, 'id', 'user', 'mailJetTemplateName')
    );
    ApiErrors.checkOnApiError(response);
    yield put(showNotifySuccess({}));
    yield put(fetchEmailTemplatesSuccessAction([...emailTemplates, response.result]));
    yield put(setSelectedEmailTemplateAction(response.result));
  } catch (e: any) {
    if (e.code === 422) {
      yield put(showNotifyFail({ message: getTranslationKey('collectionDelivery.messageToLong') }));
    }
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const deleteEmailTemplateSaga = function* ({
  payload: id
}: ISagaAction<string>): SagaIterator {
  try {
    const emailTemplates = yield select(getEmailTemplatesSelector);
    const response = yield call(Api.Emails.delete, id);
    ApiErrors.checkOnApiError(response);
    yield put(showNotifySuccess({}));
    const selectedTemplate = emailTemplates.find((item: any) => item.id !== id);
    yield put(setSelectedEmailTemplateAction(selectedTemplate || DEFAULT_EMAIL_TEMPLATE));
    yield put(
      fetchEmailTemplatesSuccessAction([...emailTemplates.filter((item: any) => item.id !== id)])
    );
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchRecipientsSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.EndCustomer.searchByEmail, { email: payload });
    ApiErrors.checkOnApiError(response);
    const emails = response.result.list.map((item: any) => item.email);
    yield put(fetchRecipientsSuccessAction(emails));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const getEmailTemplatePreviewActionSaga = function* (): SagaIterator {
  try {
    const collectionId = yield select(getCollectionId);
    const { password } = yield select(getCollectionsNew);
    const { showPasswordInMail } = yield select(getDeliveryFormSelector);
    const isPasswordPresent = password && showPasswordInMail;

    const response = yield call(Api.Emails.getPreview, {
      collectionId,
      showPasswordInMail: !!isPasswordPresent,
      recipientsEmails: 'test@tes.com'
    });
    ApiErrors.checkOnApiError(response);
    yield put(getEmailTemplatePreviewSuccessAction(response.result));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateEmailTemplateSaga = function* ({
  payload
}: ISagaAction<IEmailTemplate>): SagaIterator {
  try {
    const emailTemplates = yield select(getEmailTemplatesSelector);
    const response = yield call(
      Api.Emails.updateEmailTemplate,
      omit(payload, 'id', 'user', 'mailJetTemplateName'),
      payload.id
    );
    ApiErrors.checkOnApiError(response);
    const updatedEmailTemplates = emailTemplates.map((template: IEmailTemplate) =>
      payload.id === template.id ? response.result : template
    );

    yield put(fetchEmailTemplatesSuccessAction(updatedEmailTemplates));
    yield put(setSelectedEmailTemplateAction(response.result));
    yield put(fetchEmailPackages());
    yield put(showNotifySuccess({}));
  } catch (e: any) {
    if (e.code === 422) {
      yield put(showNotifyFail({ message: getTranslationKey('collectionDelivery.messageToLong') }));
    }
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const sendEmailSaga = function* ({ payload }: ISagaAction<ISendEmailsAction>): SagaIterator {
  try {
    const collectionId = yield select(getCollectionId);
    const response = yield call(Api.Emails.sendEmail, {
      collectionId,
      ...payload
    });
    ApiErrors.checkOnApiError(response);
    yield put(showNotifySuccess({}));
    yield put(
      setDeliveryFormAction({
        selectedTemplate: emailTemplatesInitialState.selectedTemplate,
        recipientsEmails: emailTemplatesInitialState.recipientsEmails,
        recipientsEmailsOptions: emailTemplatesInitialState.recipientsEmailsOptions,
        // emailPreviewHTML: emailTemplatesInitialState.emailPreviewHTML,
        subject: emailTemplatesInitialState.subject,
        emailsCollectedWithPurchase: emailTemplatesInitialState.emailsCollectedWithPurchase,
        sendCopyToUser: emailTemplatesInitialState.sendCopyToUser,
        showPasswordInMail: emailTemplatesInitialState.showPasswordInMail
      })
    );
  } catch (e: any) {
    if (e.code === 400) {
      yield put(
        showNotifyFail({ message: getTranslationKey('collectionDelivery.toManyRecipients') })
      );
    } else if (e.code === 422) {
      yield put(showNotifyFail({ message: getTranslationKey('collectionDelivery.messageToLong') }));
    } else if (e.code === 429) {
      yield put(
        showNotifyFail({ message: getTranslationKey('collectionDelivery.toManyRequests') })
      );
    }
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const sendLoginLinkEmail = function* ({
  payload
}: ISagaAction<ISendLoginLinkEmail>): SagaIterator {
  try {
    yield call(Api.Emails.sendLoginLinkEmail);
    payload.callback();
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
