const subdomain = window.location.hostname.split('.').shift();
const _ua = window.navigator.userAgent;
const _uaLower = _ua.toLowerCase();

const environment = {
  isRetina: window.devicePixelRatio && window.devicePixelRatio > 1,
  isIDevice: /iphone|ipod|ipad/i.test(_ua),
  isAndroidDevice: /(android)/i.test(_ua),
  isMobileChrome:
    _ua.indexOf('Android') > -1 &&
    /Chrome\/[.0-9]*/.test(_ua) &&
    _ua.indexOf('Version') === -1 &&
    _uaLower.indexOf('samsungbrowser') === -1 &&
    _uaLower.indexOf('opr/') === -1,
  isMobileIE: _ua.indexOf('Windows Phone') > -1,
  isApp: window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches,
  isMobileDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(_ua),
  isFirefox: _ua.indexOf('Firefox') > 0,
  isSamsungBrowser:
    _uaLower.indexOf('mozilla/5.0') > -1 &&
    _uaLower.indexOf('android ') > -1 &&
    _uaLower.indexOf('samsungbrowser') > -1,
  isFacebookApp() {
    const ua = _ua || window.navigator.vendor || window.opera;

    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
  },
  isDev: process.env.NODE_ENV === 'development',
  subdomain,
  upperleveldomain: window.location.hostname.split('.').join('.'),
  isEdgeOrIe:
    /MSIE 10/i.test(_ua) || /MSIE 9/i.test(_ua) || /rv:11.0/i.test(_ua) || /Edge\/\d./i.test(_ua)
};

environment.isMobileSafari =
  environment.isIDevice &&
  _ua.indexOf('Safari') > -1 &&
  _ua.indexOf('CriOS') < 0 && // chrome on ios _ua.indexOf("CriOS") > -1
  _ua.indexOf('FxiOS') < 0; // firefox on ios _ua.indexOf("FxiOS") > -1

environment.isNotSafariOnIos = environment.isIDevice && !environment.isMobileSafari;

environment.isNotChromeOnAndroid = environment.isAndroidDevice && !environment.isMobileChrome;

environment.OS = environment.isIDevice
  ? 'ios'
  : environment.isAndroidDevice
  ? 'android'
  : environment.isMobileIE
  ? 'windows'
  : 'unsupported';

environment.isTablet =
  (environment.isMobileSafari && _ua.indexOf('iPad') > -1) ||
  (environment.isMobileChrome && _ua.indexOf('Mobile') < 0);

if (process.env.NODE_ENV === 'development') {
  environment._ua = _ua;
  console.log(environment);
}

export default environment;
