import { createReducer } from 'redux-act';
import { fetchPresetsAction, fetchPresetsSuccessAction } from '../../../modules/actions';
import { IShopSalesAutomation } from '../../../modules/types';
import { IImage } from '../../../modules/reducers';

export interface IPreset {
  comments: boolean;
  default: boolean;
  download: boolean;
  downloadPin: boolean;
  galleries: any[];
  galleryStyle: string;
  shopCtaPlacement: string;
  language: number;
  name: string;
  sharing: boolean;
  showAppInstall: boolean;
  showImageCaptionInGallery: boolean;
  _userbrand: string;
  _id: string;
  _user: string;
  _domain: string;
  _watermark: string;
  _scrappbookGalleryTemplate: string;
  styles: {
    backgroundColor: string;
    buttonColor: string;
  };
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  ga_id: string;
  showOnlyPurchasedPrints: boolean;
  gtm_id: string;
  fb_pixel_id: string;
  shopActive: boolean;
  _productPricingList: string;
  salesAutomationConfig: IShopSalesAutomation;
  design: {
    disableDecorations: boolean;
    loadFonts: string[];
    imageSpacing: '';
    variables: {
      [key: string]: string;
    };
  };
  digitalShop: {
    enabled: boolean;
    manualOrderProcessing: boolean;
    digitalPricingList: string | null;
    alreadyPurchasedTier: string | null;
  };
  headerImage?: IImage;
  tags?: string[];
  messages?: string[];
  expiresInDays?: number | null;
}

export interface IPresetsReducerState {
  presetsItems: IPreset[];
  isListFetched: boolean;
}

const initialState: IPresetsReducerState = {
  presetsItems: [],
  isListFetched: false
};

export const presets = createReducer<IPresetsReducerState>({}, initialState);
presets.on(fetchPresetsAction, (state: IPresetsReducerState) => ({
  ...state,
  isListFetched: true
}));
presets.on(fetchPresetsSuccessAction, (state: IPresetsReducerState, payload: IPreset[]) => ({
  ...state,
  presetsItems: payload
}));
